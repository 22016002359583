class YandexMap {
  constructor(
    selector = '',
    options = {
      bitrixTemplatePath: '',
    },
  ) {
    this.el =
      typeof selector === 'string'
        ? document.getElementById(selector)
        : selector;
    this.map = null;
    this.mapGeoObjects = null;
    this.options = options;

    this.init();
  }

  init() {
    ymaps.ready(() => {
      this.map = this.createMap();
      this.mapGeoObjects = new ymaps.GeoObjectCollection();

      let dataPlacemarks = this.el.dataset.placemarks;

      if (dataPlacemarks) {
        dataPlacemarks = JSON.parse(dataPlacemarks);

        this.updatePlacemarks(dataPlacemarks);
      }
    });
  }

  updatePlacemarks(fetchData) {
    this.mapGeoObjects.removeAll();

    const isActive = fetchData.length === 1 ? true : false;

    fetchData.forEach((data) => {
      this.mapGeoObjects.add(this.createPlacemark(data, isActive));
    });

    this.map.geoObjects.add(this.mapGeoObjects);
  }

  createPlacemark({ coords, type, id, hintContent }, isActive = false) {
    const placemark = new ymaps.Placemark(
      coords,
      {
        hintContent: hintContent || 'Подсказка по умолчанию',
      },
      {
        iconLayout: 'default#image',
        ...this.getPlacemarkIconOptions(type, isActive),
      },
    );

    placemark.properties.set('id', String(id));
    placemark.properties.set('type', type);

    if (!isActive) {
      placemark.events.add('click', () => this.onClickPlacemark(placemark));
    }

    return placemark;
  }

  createMap() {
    return new ymaps.Map(this.el, {
      center: [55.725143, 37.760063],
      zoom: 14,
      controls: [],
    });
  }

  destroyMap() {
    this.map.destroy();
  }

  getPlacemarkIconImageHref(type, isActive = false) {
    let url = '../../../assets/img/ymaps/';
    url = url.replace('//', '/');

    switch (type) {
      case 'pickup':
        url += isActive ? 'pickup-active.svg' : 'pickup.svg';
        break;
    }

    return url;
  }

  getPlacemarkIconOptions(placemarkType, isActive = false) {
    const iconSize = isActive ? [50, 50] : [25, 25];
    const iconOffset = isActive ? [-25, -50] : [-12.5, -25];

    return {
      iconImageHref: this.getPlacemarkIconImageHref(placemarkType, isActive),
      iconImageSize: iconSize,
      iconImageOffset: iconOffset,
    };
  }

  setActivePlacemarkById(placemarkId) {
    let targetCoords = null;

    this.mapGeoObjects.each((geoObject) => {
      const geoObjectPropId = geoObject.properties.get('id');
      const geoObjectPropType = geoObject.properties.get('type');

      if (placemarkId === geoObjectPropId) {
        targetCoords = geoObject.geometry.getCoordinates();

        geoObject.options.set(
          this.getPlacemarkIconOptions(geoObjectPropType, true),
        );

        // Центрируем карту
        this.map.setCenter(targetCoords, 16);
      } else {
        geoObject.options.set(
          this.getPlacemarkIconOptions(geoObjectPropType, false),
        );
      }
    });
  }

  onClickPlacemark(placemark) {
    const placemarkPropId = placemark.properties.get('id');
    console.log('placemarkPropId: ', placemarkPropId);
    const geoObjectPropType = placemark.properties.get('type');

    this.setActivePlacemarkById(placemarkPropId);

    this.el.dispatchEvent(
      new CustomEvent('onChangePlacemark', {
        detail: {
          id: placemarkPropId,
          type: geoObjectPropType,
        },
      }),
    );
  }
}

window.YandexMap = YandexMap;
